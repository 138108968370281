import React from "react";
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
    CATCHUP_SECTION_BLUR_WIDTH,
    CATCHUP_SECTION_BLUR_HEIGHT,
} from "@constants";
import DesignFAQSection from "./components/FAQSection/FAQSection";
import { 
    FEEDBACK_SECTION_ITEMS,
    GET_IN_TOUCH_BUTTON_TEXT, 
    GET_IN_TOUCH_DESCRIPTION, 
    GET_IN_TOUCH_TITLE, 
    UI_UX_HEADING_DESCRIPTION, 
    UI_UX_PAGE_HEADING
} from "./constants";
import backgroundImage from "@images/design-page/backgroundImage.jpg";
import { StyledDesignPageWrapper, StyledCatchUpSectionContainer } from "./UIAndUXDesign.style";

const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const TextTitleSection = loadable(() =>
    import("@common/CommonSections/TextTitleSection/TextTitleSection")
);

const OffersSection = loadable(() => import("./components/OffersSection"));

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const WhyChooseSection = loadable(() => import("./components/WhyChooseSection"));

const ProcessSection = loadable(() =>
    import("./components/ProcessSection/ProcessSection")
);

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

const UIAndUXDesign = () => (
    <StyledDesignPageWrapper>
        <TextTitleSection
            title={UI_UX_PAGE_HEADING}
            description={UI_UX_HEADING_DESCRIPTION}
            backgroundImage={backgroundImage}
        />
        <OffersSection />
        <ProcessSection />
        <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
        />
        <WhyChooseSection />
        <DesignFAQSection />
        <StyledCatchUpSectionContainer>
            <CircleBLur
                width={CATCHUP_SECTION_BLUR_WIDTH}
                height={CATCHUP_SECTION_BLUR_HEIGHT}
            />
            <CrawlableSecondCatchUpSection
                title={GET_IN_TOUCH_TITLE}
                description={GET_IN_TOUCH_DESCRIPTION}
            >
            <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                {GET_IN_TOUCH_BUTTON_TEXT}
            </LinkButton>
            </CrawlableSecondCatchUpSection>
        </StyledCatchUpSectionContainer>
    </StyledDesignPageWrapper>
);

export default UIAndUXDesign;
